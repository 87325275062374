var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"planList"},[_c('div',{staticClass:"plan-filter"},[_c('van-dropdown-menu',[_c('van-dropdown-item',{attrs:{"options":_vm.planDates},on:{"change":_vm.onSelectPlanDate},model:{value:(_vm.timeRange),callback:function ($$v) {_vm.timeRange=$$v},expression:"timeRange"}}),_c('van-dropdown-item',{attrs:{"options":_vm.pointList},on:{"change":_vm.onSelectPoint},model:{value:(_vm.selectPoint),callback:function ($$v) {_vm.selectPoint=$$v},expression:"selectPoint"}})],1)],1),_vm._l((_vm.plans),function(plan,index){return _c('van-cell',{key:index,staticClass:"planItem box"},[_c('div',{staticClass:"planItem-header status"},[_c('div',{staticClass:"planItem-dateTime"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(plan.playDatetime))+" ")]),_c('div',{staticClass:"planItem-address"},[_c('span',[_c('van-icon',{attrs:{"name":"location","size":"20px","color":"#50baff"}})],1),_c('span',[_vm._v(" "+_vm._s(plan.showPoint.address)+" - "+_vm._s(plan.showPoint.name)+" ")])])]),_c('div',{staticClass:"planItem-films"},[_c('div',{staticClass:"planItem-films-header"},[_c('div',[_vm._v("顺序")]),_c('div',[_vm._v("影片名称")])]),_vm._l((plan.films),function(film,index){return _c('div',{key:index,staticClass:"planItem-films-body"},[_c('div',[_c('span',{class:['mark' + (index + 1)]},[_vm._v(_vm._s(index + 1))])]),_c('div',[_vm._v(" "+_vm._s(film.name)+" ")])])})],2),_c('div',{staticClass:"planItem-btns"},[_c('div',[(plan.planStatus === 1 && plan.report == null)?_c('van-button',{attrs:{"type":"info","size":"small","plain":"","round":"","block":"","icon":"edit"},on:{"click":function($event){return _vm.modifyPlan(plan)}}},[_vm._v(" 创建报告 ")]):_vm._e(),(
              plan.planStatus === 4 &&
              plan.report != null &&
              plan.report.status === 0
            )?_c('van-button',{attrs:{"type":"info","size":"small","plain":"","round":"","block":"","disabled":plan.report != null && plan.report.status !== 4,"icon":"loading"},on:{"click":function($event){return _vm.toPlanDetail(plan)}}},[_vm._v(" 校验中 ")]):_vm._e(),(
              plan.planStatus === 4 &&
              plan.report != null &&
              plan.report.status === 1
            )?_c('van-button',{attrs:{"type":"danger","size":"small","plain":"","round":"","block":"","icon":"loading"},on:{"click":function($event){return _vm.toPlanDetail(plan)}}},[_vm._v(" 校验失败 ")]):_vm._e(),(
              plan.planStatus === 4 &&
              plan.report != null &&
              plan.report.status === 4
            )?_c('van-button',{attrs:{"type":"info","size":"small","plain":"","round":"","block":"","icon":"close-circle"},on:{"click":function($event){return _vm.toPlanDetail(plan)}}},[_vm._v(" 已保存 ")]):_vm._e(),(plan.planStatus === 4&&plan.report!=null&&plan.report.status===2)?_c('van-button',{attrs:{"type":"info","size":"small","plain":"","round":"","block":"","icon":"clock"},on:{"click":function($event){return _vm.toPlanDetail(plan)}}},[_vm._v(" 审核中 ")]):_vm._e(),(plan.planStatus === 4&&plan.report!=null&&plan.report.status===3)?_c('van-button',{attrs:{"type":"info","size":"small","plain":"","round":"","block":"","icon":"check-circle"}},[_vm._v(" 已审核 ")]):_vm._e()],1)])])}),(_vm.plans.length === 0)?_c('van-empty',[_c('div',{staticClass:"empty-block-text"},[_vm._v("暂无放映任务")])]):_vm._e()],2),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showPoint),callback:function ($$v) {_vm.showPoint=$$v},expression:"showPoint"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.pointList},on:{"cancel":function($event){_vm.showPicker = false},"confirm":_vm.onConfirmPoint}})],1),_c('edit',{ref:"edit",on:{"success":_vm.onLoad,"update":_vm.onLoad}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }