<template>
  <div>
    <van-popup
      v-model="showModify"
      closeable
      position="bottom"
      :style="{ height: '100%' }"
      v-if="showModify"
    >
      <van-nav-bar
        title="放映报告"
        fixed
        @click-left="showModify = false"
      />
      <div class="modifyPlan">
        <div>
          <h4><span class="star">*</span>放映打卡</h4>
        </div>
        <div>
          <van-field
            v-model="tempPlanData.playDate"
            autosize
            readonly
            label="放映日期"
            type="text"
            right-icon="notes-o"
          />
          <van-field
            v-model="tempPlanData.playStartTime"
            @click="showStartTimeCalendar = true"
            autosize
            required
            readonly
            label="放映开始时间"
            placeholder="请选择放映开始时间"
            type="text"
            right-icon="clock-o"
          />
          <van-field
            v-model="tempPlanData.playEndTime"
            @click="showEndTimeCalendar = true"
            autosize
            required
            readonly
            label="放映结束时间"
            placeholder="请选择放映结束时间"
            type="text"
            right-icon="clock-o"
          />
          <van-cell v-for="(film, index) in tempPlanData.films" :key="index">
            <template #default>
              <div class="film">
                <div class="film-mark">{{ index + 1 }}</div>
                <div class="film-title">{{ film.name }}</div>
              </div>
            </template>
          </van-cell>
          <van-cell title="现场照片" center>
            <template #title>
              <span style="color: red">*</span>现场照片
            </template>
            <template #label>
              <div class="film-img">
                <van-image
                  :src="item"
                  v-for="(item, index) in tempPlanData.fileList"
                  :key="index"
                  style="width: 20vw; margin: 5px"
                  fit="contain"
                >
                  <van-icon
                    name="clear"
                    size="25"
                    color="red"
                    style="
                      position: absolute;
                      top: -5px;
                      right: -5px;
                      color: red;
                      cursor: pointer;
                    "
                    @click="deleteImg(index)"
                  ></van-icon>
                </van-image>
              </div>
            </template>
            <template #right-icon>
              <van-icon
                name="photograph"
                size="80"
                style="border-radius: 50px"
                @click="uploadImg"
                color="#007aff"
              ></van-icon>
            </template>
          </van-cell>
          <van-field
            required
            label="观众人数"
            type="text"
            readonly
            placeholder="请输入观众人数"
            right-icon="arrow-down"
            @click="psersonCountPicker = true"
            v-model="tempPlanData.audienceCount.text"
          >
          </van-field>
          <van-field
            label="放映地点"
            v-model="tempPlanData.myLocation.detailAddress"
            required
            type="textarea"
            placeholder="请点击下面地图点位按钮获取定位"
            readonly
            right-icon="location-o"
          />
          <div>
            <tian-di-tu
              ref="tianDiTu"
              v-if="showTianDiTu"
              :point="tempPlanData.showPoint"
              @update="updatePoint"
            ></tian-di-tu>
          </div>
          <van-field
            required
            label="放映设备编码"
            placeholder="请选择放映设备"
            right-icon="arrow-down"
            readonly
            @click="$refs.cinemaCompanyCardCodePicker.show()"
            v-model="tempPlanData.selectedEquipment"
          >
          </van-field>
          <van-field
            v-model="tempPlanData.filmteamName"
            readonly
            required
            label="放映员"
            placeholder="请输入放映员姓名"
            type="text"
          />
          <div>
            <van-button
              class="btn"
              type="info"
              size="small"
              block
              @click="savePlan"
            >
              确认
            </van-button>
          </div>
        </div>
      </div>
      <pre>
        {{ tempPlanData }}
      </pre>
    </van-popup>
    <!-- // 开始时间选择器弹窗 -->
    <van-popup
      v-model="showStartTimeCalendar"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="tempStartTime"
        type="time"
        @confirm="onConfirmStartTime"
        @cancel="showStartTimeCalendar = false"
        :filter="filterTime"
        title="选择放映开始时间"
      />
    </van-popup>
    <!-- // 结束时间选择器弹窗 -->
    <van-popup
      v-model="showEndTimeCalendar"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="tempEndTime"
        type="time"
        :filter="filterTime"
        @confirm="onConfirmEndTime"
        @cancel="showEndTimeCalendar = false"
        title="选择放映结束时间"
      />
    </van-popup>
    <!-- // 观众人数选择器弹窗 -->
    <van-popup
      v-model="psersonCountPicker"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-picker
        :columns="psersonCountRangeColumns()"
        title="选择观众人数"
        @confirm="confirmPersonCount"
        show-toolbar
        @cancel="psersonCountPicker = false"
      />
    </van-popup>
    <!-- // 放映设备选择器弹窗 -->
     <cinema-company-card-code-picker
      ref="cinemaCompanyCardCodePicker"
      @confirm="confirmEquipment">
    </cinema-company-card-code-picker>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { api } from '@/api'
import tianDiTu from './tianditu.vue'
import cinemaCompanyCardCodePicker from '@/components/cinemaCompanyCardPicker/index.vue'

export default {
  name: 'EditPlan',
  components: {
    tianDiTu,
    cinemaCompanyCardCodePicker,
  },
  data() {
    return {
      showModify: false, // 控制修改弹窗的显示
      showStartTimeCalendar: false, // 控制开始时间选择器的显示
      showEndTimeCalendar: false, // 控制结束时间选择器的显示
      psersonCountPicker: false, // 控制观众人数选择器的显示
      showEquipmentPicker: false,
      showTianDiTu: false, // 控制地图显示
      minTime: '06:00', // 最小放映时间
      maxTime: '24:00', // 最大放映时间
      reason: '', // 修改原因
      // 放映设备筛选key
      equipmentKey: '',
      // 放映设备选项
      equipmentOptions: [],
      tempStartTime: '', // 临时存储开始时间
      tempEndTime: '', // 临时存储结束时间
      tempPlanData: {
        myLocation: {
          detailAddress: '',
        },
        showPoint: {
          address: '',
        },
        films: [],
        playStartTime: '',
        playEndTime: '',
        equipment: [], // 存储选中的放映设备
        audienceCount: { text: '', value: '' },
        // 放映员姓名
        filmteamName: '',
        selectedEquipment: null,
      }, // 临时存储修改的计划数据
      from: {
        // 用于提交表单
        id: '',
      },
    }
  },
  mounted() {
    // 获取当前用户定位信息
  },
  methods: {
    // 显示修改弹窗并初始化数据
    show(plan) {
      // console.log('当前编辑的放映计划:', plan)
      this.modifyPlan(plan)
    },

    // 生成观众人数范围选项
    psersonCountRangeColumns() {
      return [
        { text: '5-10人', value: 5 },
        { text: '10-20人', value: 10 },
        { text: '20-30人', value: 20 },
        { text: '30-40人', value: 30 },
        { text: '40-50人', value: 40 },
        { text: '50-60人', value: 50 },
        { text: '60-70人', value: 60 },
        { text: '70-80人', value: 70 },
        { text: '80-90人', value: 80 },
        { text: '90-100人', value: 90 },
        { text: '100人以上', value: 100 },
      ]
    },

    // 过滤时间选项
    filterTime(type, options) {
      if (type === 'hour') {
        return [
          6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
          24,
        ]
      } else if (type === 'minute') {
        return [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
      }
      return options
    },
    
    // 初始化修改计划的数据
    modifyPlan(plan) {
      this.showModify = true
      this.tempPlanData = {
        ...this.tempPlanData,
        ...JSON.parse(JSON.stringify(plan)),
      }
      this.tempPlanData.reason = ''
      this.tempPlanData.playDate = this.tempPlanData.playDatetime.split(' ')[0]
      const [startTime, endTime] = this.tempPlanData.playDatetime
        .split(' ')[1]
        .split('-')
      this.tempPlanData.playStartTime = startTime.slice(0, 5)
      this.tempStartTime = startTime
      this.tempPlanData.playEndTime = endTime || ''
      this.showTianDiTu = true
      this.tempPlanData.fileList = []
      console.log(this.tempPlanData)
    },
    // 选择观影人数
    confirmPersonCount(option, index) {
      console.log('选择观众人数', option, index)
      console.log(this.psersonCountRangeColumns()[index])

      this.tempPlanData.audienceCount = this.psersonCountRangeColumns()[index]
      this.psersonCountPicker = false
    },
    
    // 确认开始时间
    onConfirmStartTime(time) {
      this.tempPlanData.playStartTime = time
      this.showStartTimeCalendar = false
      this.updatePlayDatetime()
    },

    // 确认结束时间
    onConfirmEndTime(time) {
      if (time < this.tempStartTime) {
        Toast('结束时间不能早于开始时间')
        return
      }
      this.tempPlanData.playEndTime = time
      this.showEndTimeCalendar = false
      this.updatePlayDatetime()
    },
    updatePoint(point) {
      // this.DialogLog(point)
      this.tempPlanData.myLocation = point
      // console.log('更新当前位置',point);
    },
    confirmEquipment(value){
      console.log('选择的设备编码', value)
      this.tempPlanData.selectedEquipment = value
    },

    // 更新放映日期时间
    updatePlayDatetime() {
      this.tempPlanData.playDatetime = `${this.tempPlanData.playDate} ${this.tempPlanData.playStartTime}-${this.tempPlanData.playEndTime}`
    },
    uploadImg() {
      console.log('上传图片')
      if (this.tempPlanData.fileList.length >= 2) {
        Toast('最多只能上传2张图片')
        return
      }
      //上传图片
      ZWJSBridge.chooseImage({
        upload: true,
      })
        .then((res) => {
          // console.log(result,picPath)
          this.tempPlanData.fileList.push(res.picPath[0])
          this.$forceUpdate()
        })
        .catch((error) => {
          this.DialogLog(error)
        })
    },
    // 删除图片
    deleteImg(index) {
      this.tempPlanData.fileList.splice(index, 1)
      this.$forceUpdate()
    },
    // 校验提交数据
    validatePostData(postData) {
      const errors = []

      // 校验观影人数
      if (
        typeof postData.attendanceNum !== 'number' ||
        postData.attendanceNum <= 0
      ) {
        errors.push('观影人数不能为空')
      }

      // 校验解码设备编码
      if (
        !postData.cinemaCompanyCardCode ||
        typeof postData.cinemaCompanyCardCode !== 'string'
      ) {
        errors.push('解码设备编码不能为空且必须是字符串')
      }

      // 校验播放设备编码
      if (
        !postData.cinemaCompanyMachineCode ||
        typeof postData.cinemaCompanyMachineCode !== 'string'
      ) {
        errors.push('播放设备编码不能为空且必须是字符串')
      }

      // 校验影片ID
      if (!Array.isArray(postData.filmIds) || postData.filmIds.length === 0) {
        errors.push('影片ID必须是一个非空数组')
      }

      // 校验影片计划ID
      if (!postData.filmPlanId || typeof postData.filmPlanId !== 'string') {
        errors.push('影片计划ID不能为空且必须是字符串')
      }

      // 校验位置经纬度
      if (
        typeof postData.lat !== 'number' ||
        typeof postData.lng !== 'number'
      ) {
        errors.push('位置的经度和纬度必须是数字')
      }

      // 校验放映地点
      if (!postData.remark || typeof postData.remark !== 'string') {
        errors.push('放映地点必须是一个包含地点信息的对象')
      }

      // 校验现场照片
      if (!Array.isArray(postData.pictures)) {
        errors.push('现场照片必须是一个数组')
      }

      // 校验放映日期
      if (!postData.playDate || isNaN(Date.parse(postData.playDate))) {
        errors.push('放映日期必须是有效的日期字符串')
      }

      // 校验放映结束时间和开始时间
      if (!postData.playEndTime || !postData.playStartTime) {
        errors.push('放映结束时间和开始时间不能为空')
      } else {
        const startTime = new Date('1970-01-01T' + postData.playStartTime)
        const endTime = new Date('1970-01-01T' + postData.playEndTime)
        if (startTime >= endTime) {
          errors.push('放映开始时间必须早于结束时间')
        }
      }

      // 校验放映地点ID
      if (!postData.showPointId || typeof postData.showPointId !== 'string') {
        errors.push('放映地点ID不能为空且必须是字符串')
      }

      return errors.length === 0 ? null : errors
    },
    // 保存修改的计划
    savePlan() {
      console.log('保存修改的计划', this.tempPlanData)

      const postData = {
        attendanceNum: this.tempPlanData.audienceCount.value, // 观影人数
        cinemaCompanyCardCode: this.tempPlanData.selectedEquipment, // 解码设备编码
        cinemaCompanyMachineCode: 'string', // 播放设备编码
        filmIds: this.tempPlanData.filmIds, // 影片ID
        filmPlanId: this.tempPlanData.id, // 影片计划ID
        lat: this.tempPlanData.myLocation.latitude,
        lng: this.tempPlanData.myLocation.longitude,
        remark: JSON.stringify(this.tempPlanData.myLocation), // 放映地点
        pictures: this.tempPlanData.fileList, // 现场照片
        playDate: this.tempPlanData.playDate,
        playEndTime: this.tempPlanData.playEndTime + ':00',
        playStartTime: this.tempPlanData.playStartTime + ':00',
        showPointId: this.tempPlanData.showPoint.id, //   放映地点ID
      }
      // 使用校验函数
      const errors = this.validatePostData(postData)
      if (errors) {
        console.error('数据校验失败:', errors)
        Dialog.alert({
          message: errors.join('，'),
          title: '提示',
        })
      } else {
        console.log('数据校验通过')

        // this.DialogLog(postData)
        Dialog.confirm({
          message: '确定要提交放映报告吗？',
          title: '提示',
        }).then(() => {
          api.filmmaker
            .createReport(postData)
            .then((res) => {
              Dialog.alert({
                message: '提交成功',
                title: '提示',
              }).then(() => {
                this.showModify = false
                this.$emit('update')
              })
              console.log(res)
            })
            .catch((error) => {
              this.DialogLog(error)
              console.log(error)
            })
        })
      }
    },
    DialogLog(log) {
      Dialog.alert({
        message: JSON.stringify(log),
        title: '提示',
      })
    },
  },
}
</script>

<style scoped lang="less">
.modifyPlan {
  padding: 10px;
  margin-top: 3vh;
}

.film {
  display: flex;
  align-items: center;
  &-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @color-primary;
    margin: 12px;
    position: relative;

    &:before {
      content: '';
      border: 1px solid @color-primary;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
    }
  }

  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-title {
    padding: 12px;
    background: #fff;
    border-radius: 4px;
    width: 70vw;
    color: #686b73;
  }
}
</style>
